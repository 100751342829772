import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-reveal"

export default function Konferenslokaler({ konferenslokaler }) {
  return (
    <div className="grid md:grid-cols-2 gap-8">
      {konferenslokaler.map(konferenslokal => {
        return (
          <Konferenslokal
            key={konferenslokal.id}
            konferenslokal={konferenslokal}
          />
        )
      })}
    </div>
  )
}

function Konferenslokal({ konferenslokal }) {
  const { title, featuredImage, lokalinfo } = konferenslokal
  const image = featuredImage.node.localFile.childImageSharp
  return (
    <div className="flex flex-col gap-4 md:gap-8">
      <div>
        <GatsbyImage
          image={getImage(image)}
          className="shadow-md"
          alt="Bild på lokal"
        />
      </div>
      <div className="">
        <h4>{title}</h4>
        <div dangerouslySetInnerHTML={{ __html: lokalinfo.kortBeskrivning }} />
      </div>
    </div>
  )
}
