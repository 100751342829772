import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedImagePage from "../components/common/FeaturedImagePage"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Konferenslokaler from "../components/konferens/Konferenslokaler"
import { StaticImage } from "gatsby-plugin-image"
import { Fade } from "react-reveal"
import KonferensKontakt from "../components/konferens/KonferensKontakt"
import H1 from "../components/layout/H1"
import ReactPlayer from "react-player"

export default function KonferensPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 27 }) {
        id
        title
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        konferenslokal {
          video {
            mediaItemUrl
            localFile {
              url
              publicURL
            }
          }
          lokaler {
            ... on WpLokal {
              id
              title
              lokalinfo {
                kortBeskrivning
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.6)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const { title, content, konferenslokal, featuredImage } = data.wpPage
  const image = featuredImage?.node.localFile.childImageSharp
  const konferenslokaler = konferenslokal.lokaler
  const videoUrl = konferenslokal.video?.localFile.publicURL

  return (
    <Layout>
      <Seo title="Konferens" />
      <div className="min-h-screen  flex flex-col items-center justify-star ">
        <div className="h-screen/3 md:h-screen/2 w-full relative bg-gradient-to-t bg-black">
          {image && <FeaturedImagePage image={image} />}
          <div className="absolute top-0 w-full h-full flex flex-col justify-center ">
            <div className="px-4 md:px-24 max-w-screen-2xl mx-auto w-full">
              <H1 text={title} />
            </div>
          </div>
        </div>
        <div className=" relative px-4 md:px-24 max-w-screen-2xl mx-auto w-full py-8 grid gap-4 md:gap-8 md:py-16">
          <div className="page-content ">
            <div className="space-y-8">
              <h2>Varför konferens hos oss?</h2>
              <div className="flex flex-col md:flex-row gap-4 md:gap-16">
                <div className="flex-1 ">
                  <Fade duration={2000}>
                    <div
                      className="wp-content"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </Fade>
                </div>
                <div className="flex-1 order-last space-y-4 ">
                  {videoUrl && (
                    <div className="bg-black">
                      <ReactPlayer
                        url={videoUrl}
                        controls={true}
                        width="100%"
                      />
                    </div>
                  )}
                  <Fade bottom distance="200px">
                    <StaticImage
                      src="../images/blossom.jpg"
                      className="shadow-lg"
                      alt="Blommor"
                    />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
          <div className="md:border-t-[20px] border-primary border-dotted"></div>
          <div className="space-y-8 ">
            <h3>Våra konferenslokaler</h3>
            <div>
              <Konferenslokaler konferenslokaler={konferenslokaler} />
            </div>
          </div>
          <div className="md:border-t-[20px] border-primary border-dotted"></div>
          <div>
            <h3>Intresseanmälan</h3>
          </div>
          <div>
            <KonferensKontakt />
          </div>
        </div>
      </div>
    </Layout>
  )
}
