import React from "react"
import { getCurrentDate } from "../../utils/getCurrentDate"
export default function KonferensKontakt() {
  return (
    <div>
      <form
        action="https://getform.io/f/a0cdd9cd-aba3-40e8-a29e-7c0576b2098c"
        method="POST"
        className="form space-y-8"
      >
        <div className="grid md:grid-cols-2 gap-8">
          <label htmlFor="Företagsnamn">
            Företagsnamn*
            <input type="text" name="Företagsnamn" required />
          </label>
          <label htmlFor="Referensnamn">
            Referensnamn
            <input type="text" name="Referensnamn" />
          </label>
          <label htmlFor="Namn">
            Namn
            <input type="text" name="Namn" />
          </label>
          <label htmlFor="Telefon">
            Telefon
            <input type="text" name="Telefon" />
          </label>
          <label htmlFor="Email">
            Email*
            <input type="email" name="Email" required />
          </label>
          <label htmlFor="Datum">
            Önskat datum
            <input type="date" defaultValue={getCurrentDate()} name="Datum" />
          </label>
          <label htmlFor="Antal">
            Antal gäster
            <input type="number" name="Antal" />
          </label>

          <label htmlFor="Catering">
            Catering
            <select name="Catering" className="appearance-none">
              <option>-- Ingen --</option>
              <option>Frukost</option>
              <option>Lunch</option>
              <option>Middag</option>
            </select>
          </label>
          <label htmlFor="Tid">
            Tid
            <select name="Tid" className="appearance-none">
              <option>-- Välj tid --</option>
              <option>FM</option>
              <option>EM</option>
              <option>FM + EM</option>
            </select>
          </label>
        </div>
        <label htmlFor="Meddelande">
          Meddelande
          <textarea name="Meddelande" />
        </label>
        <div>
          <button className="">Skicka</button>
        </div>
      </form>
    </div>
  )
}
